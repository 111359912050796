/*@import "variables";*/
@import "bootstrap";

$color-1: #e2e5e7;
$color-2: #f9f9f9;
$color-3: #de0935;
$color-4: #212529;
$color-5: #f5eede;
$color-6:#9e6912;
$color-7:#e6022f;
$color-8:#4e4e4e;
$color-9:#000000;

:focus {
  outline-style: none;
  outline-width: 0px !important;
  outline-color: none !important;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}



section{
  position: relative;
z-index: 2;
}

.header {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 100;
  margin-bottom: -78px;
  
  background-color: #ffffff;
}
.logo{
  width: 100px;
}
.navbar-brand{
  img{
    width: 70px;
  }
}
.header.fixed {
    position: fixed;
  top: 0;
    background-color: $color-2;
}
.nav__link {
  padding: 0px 7px;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  font-family: MullerBold, sans-serif;
  font-size: 20px;
  font-weight: 700;
}
.w-nav-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: #222222;
  padding: 20px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}
a {
  background-color: transparent;
}

@media (min-width: 1680px) {
}
@media (min-width: 991px) and (max-width: 1240px) {
 
}
@media (min-width: 991px) and (max-width: 1180px) {
 
}
@media (max-width: 767px) {
 }
/*---------------------------------------*/
body {
    font-family: MullerRegular, sans-serif;
    color: #353631;
    font-size: 24px;
    line-height: 26px;
    margin: 0px !important;
  }
  //#skrin-1{
    //background-image: url(../../images/1-s-fon.png);
   // background-position: 0px 0px, 0px 50%;
   // background-size: cover;
   // background-repeat: no-repeat;
  //}
  
  
 
  .btn{
    display: inline-block;
    padding: 5px 15px;

    background-color: #b6e824;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; 

    font-family: MullerRegular, sans-serif;
    color: #000 !important;
    font-size:  1.5rem;
    border-radius: 25px;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
  }
  
  
  .footer {
    padding-top: 30px;
    padding-bottom: 30px;
    
    
    color: #ffffff;
  }
  
  
  
  .footer__link {
    display: inline-block;
    margin-bottom: 15px;
    color: #ffffff;
    text-decoration: underline;
  }
  
  .footer__link:hover {
    text-decoration: none;
    color: #ffffff;
  }
  
  .footer__link.first {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 2px solid #ffffff;
  }
  
  .contacts {
    padding: 60px 20px 20px;
  }
  
  .quote {
    padding: 120px 20px 150px;
    background-image: url("../../images/quote-bg.webp");
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .quote__text {
    width: 45%;
    margin-bottom: 100px;
  }
  
  .contacts__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .contacts__box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .contacts__social {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  
  .contacts__fb {
    width: 21px;
    margin-right: 15px;
  }
  
  .contacts__vk {
    width: 42px;
    margin-right: 15px;
  }
  
  .contacts__youtube {
    width: 34px;
    margin-right: 15px;
  }
  
  .contacts__tg {
    width: 40px;
    margin-right: 25px;
    //filter: sepia(100) saturate(60) hue-rotate(346deg);
  }
  
  .contacts__insta {
    width: 40px;
    //filter: sepia(100) saturate(60) hue-rotate(346deg);
  }
  
  .contacts__logo {
    margin-bottom: 25px;
    width: 65px;
  }
  
  .gallery__img {
    position: relative;
    width: 25%;
  }
  
  .gallery__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 50px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  
  .how__video {
    width: 59%;
    margin-left: -25.7%;
  }
  
  .how__content {
    width: 70%;
   /* margin-left: -20%;*/

  }
  
  .sticky__img {
    position: relative;
    top: 150px;
    z-index: 10;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  
  .lbox {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 10000;
    display: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    opacity: 0;
  }
  
  .lbox__bg {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, .8);
    cursor: pointer;
  }
  
  .lbox__close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-family: 'Open Sans', sans-serif;
    color: #000;
    font-size: 45px;
    text-decoration: none;
    cursor: pointer;
  }
  
  .lbox__container {
    position: relative;
    display: block;
    width: 400px;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 35px 35px;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .reson__bg {
    position: absolute;
    right: 0px;
    bottom: 108px;
    width: 60%;
    height: 150px;
    background-color: $color-2;
  }
  
  .program__title-bg {
    position: absolute;
    top: 140px;
    right: 0px;
    width: 63%;
    height: 110px;
    background-color: $color-1;
  }
  
  .how__list-item {
    min-height: 26px;
    margin-bottom: 10px;
    padding-left: 30px;
    background-image: url("../../images/bullit.svg");
    background-position: 0px 3px;
    background-repeat: no-repeat;
  }
  
  .coach__img {
    width: 45%;
  }
  
  .promo__span {
    display: block;
  }
  
  html.w-mod-js *[data-ix="header-move"] {
    opacity: 0;
    -webkit-transform: translate(0px, 50px);
    -ms-transform: translate(0px, 50px);
    transform: translate(0px, 50px);
  }
  
  @media (max-width: 991px) {
    body {
      font-size: 20px;
      line-height: 22px;
    }
    h2 {
      font-size: 32px;
      line-height: 36px;
    }
    
    .navbar {
      padding-top: 15px;
      padding-right: 10px;
      padding-left: 20px;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
    .nav__logo {
      margin-top: -10px;
      -webkit-box-ordinal-group: 0;
      -webkit-order: -1;
      -ms-flex-order: -1;
      order: -1;
    }
    .nav__menu {
      top: 0px;
      right: 0px;
      padding: 15px 10px 15px 15px;
      background-color: $color-2;
    }
    .nav__link {
      padding: 5px;
      font-family: MullerRegular, sans-serif;
      font-size: 20px;
    }
    .btn {
      min-width: auto;
      font-size: 20px;
    }
    .btn.nav__btn {
      margin-bottom: 0px;
      -webkit-box-ordinal-group: 1;
      -webkit-order: 0;
      -ms-flex-order: 0;
      
    }
    .nav__menu-btn {
      padding: 5px;
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
      -ms-flex-order: 1;
      order: 1;
      font-size: 32px;
    }
    .nav__menu-btn.w--open {
      color: #48453d;
    }
    .review {
      padding-top: 50px;
      padding-bottom: 10px;
    }
    .review.bottom {
      padding-bottom: 70px;
    }
    .review__content {
      width: 90%;
    }
    .formula__box {
      position: static;
      width: 47%;
      height: 125px;
      padding-top: 0px;
      padding-bottom: 0px;
      font-size: 18px;
      line-height: 20px;
    }
    .formula__box.left {
      margin-bottom: 60px;
    }
    .review__box {
      margin-bottom: 50px;
    }
    .section__subtitle {
      top: -15px;
      margin-bottom: 15px;
      font-size: 32px;
    }
    .formula__bg {
      margin-right: -20px;
      margin-left: -20px;
    }
    .review__video {
      left: -50px;
    }
    .how {
      padding-right: 20px;
      padding-left: 20px;
    }
    .steps {
      padding-right: 20px;
      padding-left: 20px;
    }
    .formula {
      padding-right: 20px;
      padding-left: 20px;
    }
    .formula__wrapper {
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .arrow.reason__arrow {
      bottom: 16%;
    }
    .steps__box {
      margin-bottom: 30px;
    }
    .promo {
      background-image: -webkit-linear-gradient(270deg, null, null), url("../../images/promo-bg.webp");
      background-image: linear-gradient(180deg, null, null), url("../../images/promo-bg.webp");
      background-position: 0px 0px, 50% 50%;
    }
    .formula__sign {
      left: 50%;
      right: 0%;
      width: 32px;
      -webkit-transform: translate(-50%, 0px);
      -ms-transform: translate(-50%, 0px);
      transform: translate(-50%, 0px);
    }
    .formula__sign.center {
      left: 76%;
      top: 50%;
      -webkit-transform: translate(0px, -50%) translate(-76%, 0px);
      -ms-transform: translate(0px, -50%) translate(-76%, 0px);
      transform: translate(0px, -50%) translate(-76%, 0px);
    }
    .formula__sign.up {
      left: 50%;
      right: 0%;
      -webkit-transform: translate(-50%, 0px);
      -ms-transform: translate(-50%, 0px);
      transform: translate(-50%, 0px);
    }
    .reason__img {
      width: 32%;
    }
    .reason__list {
      width: 65%;
    }
    .reason__list-item {
      padding-left: 27px;
      background-size: 17px;
    }
    
    .cash-img {
      left: -26%;
      bottom: 10%;
      width: 55vw;
    }
    .coins-img {
      left: 22%;
      bottom: 10%;
      width: 24vw;
    }
    .reason__offer-img {
      width: 18vw;
    }
    .format__box {
      margin-bottom: 60px;
    }
    .format__title {
      left: -13px;
      top: -39px;
    }
    .format__number {
      width: 126px;
      margin-right: 12px;
      font-size: 90px;
    }
    .format__description {
      min-height: 95px;
      padding-top: 20px;
      padding-bottom: 15px;
      padding-left: 152px;
    }
    .format__subtitle {
      margin-bottom: 10px;
      font-size: 32px;
    }
    .reason__content {
      font-size: 18px;
    }
    .program {
      padding-top: 79px;
    }
    .program__offer {
      width: 64%;
      margin-right: 0px;
      margin-bottom: 100px;
      padding-left: 2%;
      font-size: 18px;
    }
    .program__offer-img {
      top: -200px;
      width: 160px;
    }
    .program__dop {
      right: -20px;
      width: 39%;
    }
    .program__dop.second {
      right: 20px;
      width: 40%;
    }
    .main__title {
      margin-left: -20px;
      padding-top: 20px;
      padding-bottom: 15px;
      padding-left: 20px;
      font-size: 1em;
      line-height: 1.2em;
    }
    .list-item {
      padding-left: 27px;
      background-size: 17px;
    }
    .dop__img {
      left: -20px;
      top: -20px;
      margin-bottom: 20px;
    }
    .dop__img.second {
      left: 20px;
    }
    .sticky__wrapper {
      top: -25px;
      padding-top: 98px;
    }
    .dop__list {
      left: -20px;
      padding-left: 25px;
    }
    .dop__list.second {
      left: 20px;
    }
    .dop__subtitle {
      left: -20px;
    }
    .dop__subtitle.second {
      left: 20px;
    }
    .number__title {
      bottom: -15px;
      font-size: 32px;
    }
    .number__count {
      font-size: 130px;
      line-height: 68px;
    }
    .main__number {
      top: -91px;
      min-height: 93px;
    }
    .program__container {
      padding: 150px 20px 40px;
    }
    .tarif__bg {
      margin-top: 70px;
      padding-bottom: 50px;
    }
    .tarif__subtitle {
     /* margin-top: -85px;*/
      margin-bottom: 30px;
      font-size: 32px;
     // color: #ffffff;
    }
    .tarif__box {
      position: static;
      width: 45%;
      margin-bottom: 35px;
      padding-top: 0px;
      padding-bottom: 0px;
      font-size: 18px;
      line-height: 20px;
    }
    .tarif__title {
      padding: 25px 15px;
      font-size: 36px;
    }
    .tarif__oplata {
      padding: 25px 20px;
      font-size: 22px;
    }
    .tarif__description {
      padding: 15px;
    }
    .tarif__description.exclusive {
      padding-top: 0px;
    }
    .tarif__list {
      margin-bottom: 35px;
    }
    .tarif__wrapper {
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .goals {
      padding-top: 60px;
    }
    .goals__description {
      width: 48%;
      margin-left: -20px;
    }
    .goals__img-wrapper {
      margin-top: 20px;
      margin-right: -20px;
    }
    .goals__wrapper {
      margin-top: 70px;
      padding-bottom: 40px;
    }
    .goals__img-box {
      top: -20px;
      right: 0px;
    }
    .goals__list {
      top: -30px;
      padding-left: 20px;
    }
    .goals__img {
      top: 20px;
      right: 20px;
    }
    .slider__wrapper {
      width: 100%;
      min-height: auto;
    }
    .coach__wrapper {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .coach__description {
      width: 54%;
    }
    .gallery {
      padding-top: 50px;
      padding-bottom: 60px;
    }
    .guarantee__img {
      max-width: 40%;
    }
    .guarantee__wrapper {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .guarantee__box {
      width: 65%;
    }
    .quote {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    .quote__text {
      width: 50%;
      margin-bottom: 50px;
    }
    .sticky__img {
      max-width: 100px;
    }
    .reson__bg {
      bottom: 57px;
    }
    .program__title-bg {
      top: 150px;
      height: 110px;
    }
    .how__list-item {
      background-position: 0px 0px;
    }
  }
  
  @media (max-width: 767px) {
    body {
      font-size: 18px;
    }
    h1 {
      font-size: 80px;
    }
    h2 {
      font-size: 26px;
      line-height: 28px;
    }
    
    .navbar {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .nav__logo {
      -webkit-box-ordinal-group: 0;
      -webkit-order: -1;
      -ms-flex-order: -1;
      order: -1;
    }
    .btn {
      padding-right: 25px;
      padding-left: 25px;
      font-size: 18px;
      width: 100%;
    }
    .btn.reason__btn {
      left: 50%;
      top: -20px;
      -webkit-transform: translate(-50%, 0px);
      -ms-transform: translate(-50%, 0px);
      transform: translate(-50%, 0px);
    }
    .btn.how__btn {
      position: relative;
      bottom: -20px;
      float: none;
    }
    .nav__menu-btn {
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
      -ms-flex-order: 1;
      order: 1;
    }
    
  }
  
  @media (max-width: 479px) {
    body {
      font-size: 16px;
      line-height: 18px;
    }
    h1 {
      font-size: 54px;
    }
    .navbar {
      padding: 10px 0px 10px 15px;
    }
    .nav__logo {
      width: 108px;
    }
    .nav__menu {
      padding-left: 10px;
    }
    .btn.nav__btn {
      font-size: 18px;
      margin-bottom: 10px;
    }
    
    .footer__link {
      margin-right: 15%;
      margin-left: 15%;
    }
    .footer__link.first {
      margin-right: 15%;
      margin-bottom: 0px;
      padding-right: 10px;
      padding-bottom: 5px;
      padding-left: 10px;
      border-right-style: none;
    }
    
    .m-btn{
      display: flex;
      justify-content: center;
    }
  }
  
  @media (min-width: 992px){
    
  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .nastavnik{
    width: 100%;
    height:auto ;
    max-width: 500px;
  }

  .fan-img{
    width: auto;
    height: 350px;
    padding:6px;
    border: 3px solid #b6e824;
  }
}
h1,#reg-h3{
  font-size:4rem;
}
  @media (max-width: 479px) {
    h1{
      font-size: 2.5rem !important;
      text-align: center;
      margin-bottom: 30px;
      margin-top: -30px;
    }
    #reg-h3{
      font-size: 2rem !important;
    }
    #skrin-2 h2{
      margin-bottom: 30px;
    }
    #skrin-3{
      text-align: center;
    }
    #skrin-5 .btn{
      width: 100% !important;
    }
    #reg-fon {
      width: 100%;
    }
    .fan-img{
      width:100%;
      height: auto;
    }
    .nav-item{
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  
  .am-wrapper{
    float:left;
    position:relative;
    overflow:hidden;
  }
  .am-wrapper img{
    position:absolute;
    outline:none;
  }
  .trener{
    width:auto;
    max-height:90%;
    @media (max-width: 479px) {
      width:100%;
    }
  }
.s-5-box{
  background: radial-gradient(44.63% 73.06% at 10.59% 21.29%, #E2D637 0%, #B6E824 100%)
}
